import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';
import SlideList from '../DashboardPages/features/SliderComponent';
import ProjectList from '../DashboardPages/features/ProjectList';
import EventList from '../DashboardPages/features/EventsList';
import api from '../../services/api';
import { hash, urlWebsite } from '../../config';
import { Button } from '../../components/Button/styles';
import SliderContainer from '../../components/SliderContainer';
import { FaBrain, FaSearch, FaStar } from 'react-icons/fa';
import Calendar from './modules/Calendar';
import SelectSimple from '../../components/Forms/SelectSimple';
import EventsListDisplay from '../DashboardPages/features/EventsListDisplay';
import PosterListDisplay from '../DashboardPages/features/PosterListDisplay';
import TestimonialListDisplay from '../DashboardPages/features/TestimonialDisplay';
import { ContactIcons, ContactIconsOrange } from '../../components/Menu';
import themeDefault from '../../styles/ThemeComponents';
import Input from '../../components/Forms/Input';
import SignUp from '../SignUp';
import Youtube from '../../components/Youtube';
import ContagemRegressiva from '../../components/ContagemRegressiva';
import { loadFromJson } from '../../utils/loadFromJson';
import Speakers from '../Speakers';


interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home2: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const sendToEvents = () => {

    let link = currentCategory ? `/eventos/${currentCategory}` : `/eventos`;

    if (search) {
      link = `${link}?search=${encodeURIComponent(search)}`;
    }

    window.location.href = link;

  }

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalCapa',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
              <h2 style={{ padding: '10px', width: '100%', textAlign: 'center', color: 'rgb(0,14,64)' }}>Clique na imagem para acessar</h2>
              {data?.popup_images.map(popup => {
                return <a onClick={() => removeModal('popup')} key={`popup-url-${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '15px auto' }} href={`${popup?.link_url}`}>
                  <img src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />
                </a>

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);




  }

  useEffect(() => {
    loadConfig();
    /*
        addModal({
       key:'popup',
       theme: 'whiteModal2',
       title:' ',
       content: <><div style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}>
         <Link onClick={() => removeModal('popup')} style={{width:'100%', maxWidth:'500px', margin: '0px auto'}} to="/app/sessao-clinica-hospitais-dasa-brasil/login">
           <img src="/apoio/sessao-clinica.png" style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}/>
         </Link>
         </div></>
     }  ) */
  }, [])

  const confirmPresense = (data) => {

  }


  const handle = () => { }

  return (<>
    <Container className='bg1'>
      <Capa  >
        <div className='p50 homeTitle'>




          <h2  >Conecte-se com as reflexões que vão moldar o futuro da saúde no trabalho</h2>

          <p className='subtitle'> <strong  >  Dias 9 e 10 de maio de 2023</strong> </p>

          <p className='description'><strong >Evento híbrido</strong><br />

            Evento online com transmissão ao vivo de todas as palestras.<br />
            Convidados presenciais para experiência imersiva.
          </p>

          <a href="/app/43-semana-cientifica-hcpa/inscricao" className="buttonBigBlue"><button>INSCREVA-SE</button></a>
          <p className='description' style={{ marginTop: '25px', marginBottom: '5px' }}> Faltam </p>
          <ContagemRegressiva />
        </div>





      </Capa>




    </Container>
    <Container className='bg2  noPadding noMax' style={{ minHeight: 'auto' }}>
      <Capa className=' noPadding noMax' style={{ marginBottom: '25px', flexDirection: 'column' }}>
        <div className='p50 homeTitle'>




          <h2  >A conversa sobre saúde no trabalho não pode parar</h2>



          <p className='description'>Em sua terceira edição, o 43ª Semana Científica do HCPA vem aí! O mais completo evento do Brasil acontecerá nos dias <strong>9 e 10 de maio</strong> em formato híbrido.
            <br /><br />


            Este ano, especialistas falarão sobre saúde mental, promoção da saúde, segurança e saúde no trabalho, trazendo <strong>reflexões conectadas ao ESG</strong>, uma das temáticas mais importantes da atualidade.

            <br /><br />

            Promovido pelo Serviço Social da Indústria do RS, o 43ª Semana Científica do HCPA prepara <strong>pessoas e empresas</strong> para uma gestão adequada e avançada da <strong>saúde integral no trabalho</strong>, apresentando formas de promover <strong>ambientes laborais saudáveis</strong> em cenários de constantes mudanças.
          </p>
          <div style={{ width: '100%', maxWidth: '612px', display: 'flex', justifyContent: 'flex-start' }}>
            <a href="/app/43-semana-cientifica-hcpa/inscricao" className="buttonBigBlue"><button>INSCREVA-SE</button></a>
          </div>



        </div>



      </Capa>

    </Container>

    <Container className='bg3' >
      <Capa >
        <div className='p50 homeTitle' style={{ width: '100%' }}>




          <h2  >Como foram as edições<br />

            anteriores do 43ª Semana Científica do HCPA</h2>

          <div className='split'>
            <div className='highlights'>
              <h2>MAIS DE 20 PALESTRANTES</h2>
              <p>especialistas nacionas e internacionais</p>
            </div>
            <div className='highlights'>
              <h2>MAIS DE 50 MIL INSCRITOS</h2>
              <p>nas duas edições anteriores</p>
            </div>



          </div>
          <div style={{ maxWidth: '811px', width: '100%' }}>
            <Youtube id='22o9PZFGX_0' />
          </div>
        </div>





      </Capa>

    </Container>
    <Container className='bg4' >
      <Capa  >
        <div className='p50 homeTitle' style={{ width: '100%' }}>




          <h2  >2023 será ainda melhor!  </h2>


          <a style={{ width: '100%', maxWidth: '388px' }} href="/app/43-semana-cientifica-hcpa/inscricao"> <button className="buttonBigGreen" type="submit">
            Inscreva-se!


          </button>
          </a>

        </div>

      </Capa>

    </Container>
  </>
  );
};
export default Home2;
