import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import VideoBackground from '../../components/VideoBackground';
import { Link } from 'react-router-dom';

const FooterLayout: React.FC = ({ children }) => {
  return (<><div style={{ zIndex: -1 }}>
    <Particles
      params={{
        particles: {
          lineLinked: {
            enable: false,
            distance: 250,
            color: '#fff',
            width: 1,
            opacity: 1
          },
          move: {
            speed: 0.2,
            direction: 'right',
            collisions: false,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },


          },
          opacity: {
            value: 0.7,
            random: false,
            animation: {
              enable: false
            }

          },

          shape: {
            images: [
              { src: '/apoio/nuvem1.png', width: 120 },
              { src: '/apoio/nuvem2.png', width: 120 },
              { src: '/apoio/nuvem3.png', width: 120 }

            ],
            type: 'image',


          },

          color: { value: '#946b4e' },

          number: {
            density: {
              enable: true,
              value_area: 2400,
            },
            value: 40,
          },
          size: {
            value: 120,
            random: true,
          },
        },
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
      }}
    />
  </div>

    <div className='backImage mobileOnly'>
      <div>
        {/*   <img className='eye' src="/apoio/eye.gif" /> */}
        <img className='backImageBody' src="/apoio/imagem.png" />
      </div>
    </div>

    <div className='backImage computerOnly'>
      <div>
        {/* <img className='eye' src="/apoio/eye.gif" /> */}
        <img className='backImageBody' src="/apoio/imagem.png" />
      </div>
    </div>

  </>
  );
};

export default FooterLayout;
