import styled from "styled-components";
import themeDefault from "../../../../../styles/ThemeComponents";



export const Title = styled.h2`
font-size: 42px; color: #fff; text-align: left; margin: 30px auto; margin-top: 50px;

   @media (max-width: 750px) {
    font-size: 24px; color: #fff; text-align: center; margin: 25px auto; 
    margin-bottom: 0px;

}
  `;


export const FooterElement = styled.div`
  width: 100%;
   position: fixed; 
   bottom: 0px; 
   left: 0px; 
   display: flex; 
   align-items: flex-start; 
   justify-content: flex-end; 
   background: rgb(241, 90, 35);

   @media (max-width: 1060px) {
position: relative ;
top: auto;
bottom: auto;
left: auto;
right: auto;
margin-top: 150px;

}
  `;

export const Block = styled.div`
  width: 100%;
max-width: 450px;
display: flex;
justify-content: flex-start;
align-items: stretch;
flex-wrap: wrap;
cursor: pointer;
 

display: flex;
justify-content: flex-start   ;
align-items: center;

color : ${themeDefault.color};


margin: 25px auto;
justify-content: center;

padding: 0px 16px !important;


@media (max-width: 750px) {
justify-content: center   ;
max-width: 100%;

}
 

.eventTitle{
    padding: 10px ;
    height: 100% ;
    width: 100%;
    display: flex ;
    align-items: center;
    justify-content: center;

    >h2{
      text-align: center;
    }
  }

  .mainFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .posterDisplay {
 
    
 
    margin: 10px;
    margin-left: 0px;
    background: none;
    max-width: 600px;
    width: 100%;
    @media (max-width: 1310px) {
      max-width: 600px;
    width: 100%;
  }

  @media (max-width: 750px) {
    margin: 10px 0px;
    flex-direction: column;
  }
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    color: #fff;
    padding: 10px;
    padding-left: 0px;

    @media (max-width: 540px) {
      padding: 0px ;
  }
  }



  .posterTitle {
    font-size: 8px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    font-weight: bold;
  }

  .posterAuthor,
  .posterCoAuthor {
    font-size: 8px;
    width: 100%;
   
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .posterImage {
    width: 100%;
  
  }

.posterContentGroup{
   display:flex;
   align-items:stretch;
    justify-content:center;
     position:relative;


}

.contentDiv{
  width: 100%;
  max-width: 300px;
  display: flex ;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5px ;
  margin-left: 10px;

  >p.episodio{
    font-size: 12px;
    margin-bottom: 10px;
  }

  >h2{
    font-size: 14px;
    color: #a6e4ff;
  }

  p{
    font-size: 12px;
  }
}

  .posterImageDiv{
  
    .play{
      position: absolute ;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      width: 100%;
      display: flex ;
      align-items: center;
      justify-content: center;

      >svg{
        min-width: 80px;
        min-height: 80px;
        color: #fff;
        opacity: 0.3;
        transition: 0.5s all;

      }

      &:hover{
        >svg{
          min-width: 70px;
        min-height: 70px;
        opacity: 0.7;
      }
      }
    }

    width: 150px;
    min-width: 150px;
    border-radius: 10px;
    display: flex;
    border: 2px solid #fff !important;
position: relative ;
    @media (max-width: 1310px) {
     
      width: 150px;
    min-width: 150px;
  }

  @media (max-width: 750px) {
  
    width: 100%;
    max-width: 350px;
  }

  img{
    border-radius: 10px;
  }
  }

  .starsTime {
    max-width: 100%;
    display: flex ;
    align-items: center;
    justify-content: flex-start;

    svg{
      margin: 0px;
      margin-right: 10px;
      min-width: 15px;
      max-width: 15px;
      margin: 0px 10px;
    }

    @media (max-width: 550px) {

      flex-wrap:  wrap;

      .starCase {
        margin-bottom: 10px;
      }

    svg{
      margin: 0px;
    
      margin-right: 10px;
      min-width: 15px;
      max-width: 15px;
    }
  }

  }

  .posterContent {
    padding: 20px;
    display: flex ;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    max-width: 310px;

    h2{
      font-size: 12px;
      color: ${themeDefault.textComponent.title.color};
    }

    p{
      font-size: 12px;
      color: ${themeDefault.textComponent.paragraph.color};
    }

    @media (max-width: 540px) {
      padding: 0px;
  }
  }


  .posterKeywords{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    >strong{
      padding: 2px 5px ;
      background: #f15a23;
      border-radius: 3px;
      margin-right: 3px;
      font-size: 9px;
      font-weight: bold;
    }
  }

  .starCase{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
     

    >svg{
      margin-right: 3px;

    }
  }

  > h2 {
    display: flex;
    justify-content: center;
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 750px) {
    padding: 0px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    > tbody {
      > tr {
        border-top: 1px solid #555;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  button{
    background: #333;
  }


  .buttonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
margin-top: 20px;

    >a {
      display: flex;
      padding: 15px;
      width: 100%;
      margin: 10px;
      background: rgb(0,14,64);
      color: #fff;
      border-radius: 15px;
      font-size: 18px;
      text-align: center;
      justify-content:center;
      box-shadow: 2px 10px 10px rgba(0,0,0,0.5);
    }
  }

  .homeTitle{
    font-size: 28px;
    p,h2,h3{
    text-align: left !important;
    width: 100%;
    }

    @media (max-width: 750px) {
      font-size: 22px;
    
  }
  }

  .homeSelect{
    padding: 15px;
    background: #fff ;
    border-radius: 10px;
    border: 0px;
    margin: 3px;
    option{
      padding: 15px 15px ;
    }
 
 
  }

  .homeSelectReverse{
    padding: 11px 0px;
    margin: 3px;
    border: 0px;
    background: ${themeDefault.alternativeButton.background}   ;
    color: ${themeDefault.alternativeButton.color};
    border-radius: 10px;

  >svg{
    font-size: 24px;
    width: 35px;
    min-width: 35px;
  }
 
 
  }


  .typeButtonDiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
width: 100%;
margin-top: 10px;


    .typeButtonInitial {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 5px;
      color: ${themeDefault.alternativeButton.color};
      background: ${themeDefault.alternativeButton.background};
      min-width: 100px;
      font-weight: bold;
      border : 0px;
      border-radius: 5px;
      margin: 5px;
      font-size: 12px;

    }

    .typeButtonDate {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 5px;
      color: rgb(0,14,64);
      background:#a6e4ff;
      width: 300px;
      font-weight: bold;
      border : 0px;
      border-radius: 5px;
      margin: 5px;
      margin-top: 0px;
      
      font-size: 10px;

    }

    .typeButton {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 5px;
      color: #000e40;
      background: #b1c9df;
      min-width: 100px;
      font-weight: bold;
      border : 0px;
      border-radius: 5px;
      margin: 5px;
      font-size: 12px;
    }

  }

`