import React, { useEffect, useState } from 'react';
import { Capa, Photo } from './styles';
import { Link, useParams } from 'react-router-dom';
import SliderComponent from '../SliderComponent';
import api from '../../../../services/api';
import { urlWebsite } from '../../../../config';
import { day, hour, month } from '../../../../utils/date';
import { startOfDay } from 'date-fns';



const EventsProjectList : React.FC = () => {

  const {projectHash} = useParams<Record<string,any>>();

  const [highlights,setHighlights] = useState<Array<Record<string,any>>>([]);
  const [projects,setProjects] = useState<Array<Record<string,any>>>([]);

  const loadProjects = async () => {
    const response = await api.get(`/events-list/${projectHash}`);

    let highlight : Array<Record<string,any>> = [];
    let otherProjects : Array<Record<string,any>> = [];

    if(response?.data){

      response?.data.rows.map( project  => {

        if(new Date(project?.start_at) > startOfDay(new Date())){
        project.day = day(project.start_at);
        project.month = month(project.start_at);
        project.hour = hour(project.start_at);

     
          otherProjects.push(project);
        
      }
      else{
        project.day = day(project.start_at);
        project.month = month(project.start_at);
        project.hour = hour(project.start_at);

     
        highlight.push(project);

      }
      })

      highlight = highlight.sort((a,b) => a.start_at < b.start_at ? -1 : a.start_at > b.start_at ? 1 : 0);
      otherProjects = otherProjects.sort((a,b) => a.start_at < b.start_at ? -1 : a.start_at > b.start_at ? 1 : 0)

      setHighlights(highlight);
      setProjects(otherProjects);
    }
  } 

  useEffect(() => {
    loadProjects();
  },[])

  const [slides, setSlides] = useState<Array<JSX.Element>>([] as Array<JSX.Element> );


 
    return     <Capa>  <div className='mainTitle'>

    
    
    {projects.length > 0 ? <><div style={{display: 'flex', justifyContent:'center', marginTop: '10px'}}>
    <h2 style={{marginBottom:'25px', margin: '10px auto', padding: '15px', borderRadius:'15px', background: '#fff', color: 'rgb(45,77,181)'}}>PRÓXIMOS EVENTOS</h2>
    </div>
    
    {projects.map(prevProject => {

return <Link style={{position: 'relative', color: '#fff',width: '100%',  fontSize: '18px'}} to={`/app/${prevProject.project_id}/event/${prevProject.url}`}>
          
          
          
<div className='proximoseventos'>
  <div className='data-proximoseventos'>
<h2>{prevProject.day}</h2>
<h3>{prevProject.month}</h3>
<p style={{fontSize:'12px', color: '#333'}}>{prevProject.hour}</p>
  </div>
  <div>
<h2 className='title-proximoseventos'>{prevProject.title}</h2>
  </div>
  </div>   


</Link>
    })}
</> : <></>}

{highlights.length > 0 ? <><div style={{display: 'flex', justifyContent:'center'}}>
    
    <h2 style={{marginBottom:'25px', margin: '10px auto', background: 'rgb(45,77,181)', color: '#fff'}}>EVENTOS ANTERIORES</h2>
    </div>
    
    {highlights.map(highProject => {

return <Link style={{position: 'relative', color: '#fff', width: '100%', fontSize: '18px'}} to={`/app/${highProject.project_id}/event/${highProject.url}`}>
          
          
          
<div className='proximoseventos'>
  <div className='data-proximoseventos'>
<h2>{highProject.day}</h2>
<h3>{highProject.month}</h3>
<h3>{highProject.hour}</h3>
  </div>
  <div>
<h2 className='title-proximoseventos'>{highProject.title}</h2>
  </div>
  </div>   


</Link>
    })}

</>: <></>}
    
      </div> </Capa>
}

export default EventsProjectList;