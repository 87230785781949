import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const ProductsAdmin: React.FC = () => {
  const endpoint = '/products-manager';
  const title = 'Produtos';
  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    hash_link: { ref: 'hash_link', column: 'hash_link', label: 'Link' },

    title: { ref: 'title', column: 'title', label: 'Título' },
    price: { ref: 'price', column: 'price', label: 'Preço', mask: 'currency' },
    before_price: {
      ref: 'before_price',
      column: 'before_price',
      label: 'Preço Cheio',
      mask: 'currency',
    },
    limited_quantities: {
      ref: 'limited_quantities',
      column: 'limited_quantities_response',
      includeColumn: 'title',
      label: 'Vagas Limitadas?',
      filter: true,
    },

    total_quantities: {
      ref: 'total_quantities',
      column: 'total_quantities',
      label: 'Total de Vagas',
    },

    available_quantities: {
      ref: 'available_quantities',
      column: 'available_quantities',
      label: 'Vagas Disponíveis',
    },

    sold_quantities: {
      ref: 'sold_quantities',
      column: 'sold_quantities',
      label: 'Ingressos Vendidos',
    },

    waiting_quantities: {
      ref: 'waiting_quantities',
      column: 'waiting_quantities',
      label: 'Ingressos Aguardando Pg.',
    },

    status_id: {
      ref: 'status_id',
      column: 'status_id_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    valid_id: {
      ref: 'valid_id',
      column: 'valid_id_response',
      includeColumn: 'title',
      label: 'Valido?',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };
  const formSchema = {

    category_id: {
      model: 'selectSimple',
      name: 'category_id',
      label: 'Categoria',

      options: [
        { label: 'Presencial', value: 'local' },
        { label: 'Presencial sem QRCode', value: 'local_no_qrcode' },
        { label: 'Online', value: 'online' },
        { label: 'Híbrido', value: 'hybrid' },
      ]

    },
    status_id: {
      model: 'selectSimple',
      name: 'status_id',
      label: 'Ativo?',
      defaultValue: 'yes',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]

    },
    hide_sale_info: {
      model: 'selectSimple',
      name: 'hide_sale_info',
      label: 'Esconder informações da Venda?',
      defaultValue: 'no',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]

    },
    valid_id: {
      model: 'selectSimple',
      name: 'valid_id',
      label: 'Válido?',
      defaultValue: 'yes',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]

    },


    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    hash_link: { model: 'input', type: 'text', name: 'hash_link', label: 'Link' },
    description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo' },
    price: {
      model: 'currencyInput',
      name: 'price',
      label: 'Preço',
      defaultValue: '0',
    },
    before_price: {
      model: 'currencyInput',
      name: 'before_price',
      label: 'Preço Cheio',
      defaultValue: '0',
    },
    limited_quantities: {
      model: 'selectSimple',
      name: 'limited_quantities',
      label: 'Limitar Vagas?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    email_title: { model: 'input', type: 'text', name: 'email_title', label: 'Título do e-mail' },
    email_message: { model: 'richText', type: 'text', name: 'email_message', label: 'Corpo do e-mail' },

    total_quantities: {
      model: 'input',
      type: 'text',
      name: 'total_quantities',
      label: 'Total de Vagas',
      defaultValue: 0,
    },
    courses: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
            {data.id})
            {' '}
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data.id})
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },


    fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'fields',
      label: 'Campos',
      show: 'title',
      list: [
        { label: 'Campo', name: 'field', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'input' },
        {
          label: 'Mascara', name: 'mask', type: 'select', options: [

            { label: 'Nenhuma', text: 'Nenhuma', value: '' },
            { label: '0000', text: '0000', value: '1000' },
          ]
        },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [
            { label: 'Estrelas', text: 'Estrelas', value: 'star' },
            { label: '1 a 10', text: '1 a 10', value: 'range' },
            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Lista de Textos', text: 'Lista de Textos', value: 'arrayInputIncluder' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
            { label: 'Imagem', text: 'Imagem', value: 'image' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },
        { label: 'Limite', name: 'limit', type: 'input' },
        { label: 'Label', name: 'label', type: 'input' },

      ]
    },

  };

  const formSchemaUpdate = {

    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'logo',
    },
    email_header: {
      model: 'imageGalleryInputSingle',

      name: 'email_header',
      label: 'Cabeçalho de Email',
      list: [
        { label: 'Imagem', name: 'url' },



      ],
    },
    email_footer: {
      model: 'imageGalleryInputSingle',

      name: 'email_footer',
      label: 'Rodapé de Email',
      list: [
        { label: 'Imagem', name: 'url' },



      ],
    },
    email_full_image: {
      model: 'imageGalleryInputSingle',

      name: 'email_full_image',
      label: 'Email de imagem completa',
      list: [
        { label: 'Imagem', name: 'url' },


      ],
    },
    ...formSchema
  };



  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ProductsAdmin;
