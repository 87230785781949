import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 200px;
  padding: 20px;
  background: #fff;

  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div``;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 30px 10px;
  background: none;
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;
  position: relative;
  bottom: 0px;
  left: 0px;

  background: url('/apoio/bg5.jpeg') no-repeat 0% 50%;
 background-attachment: scroll;
   background-size: cover;
 
 padding: 100px 0px;

 >div{ width: 100%; 
  max-width: 1200px;
  display: flex ;
  align-items: align-items;
  justify-content: space-between;

  flex-direction: row;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  >div{
   width: 100%;
   max-width: 500px;
   display: flex ;
   justify-content: space-around;
   align-items: center;

   @media (max-width: 750px) {
    justify-content: center;
    gap: 20px;
    margin: 20px 0px;
  }

   .logoFooter{
    width: 211px;
    max-width: 100%;
   }

   .socialIcon{
     width: 50px;
   }

 }

}
 



`;

export const SponsorGrid = styled.div<IBackground>`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

interface IBackground {
  background?: string;
}
export const Sponsor = styled.div<IBackground>`
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;

  width: 160px;
  height: 105px;
  margin: 5px;

  @media (max-width: 1200px) {
    width: 120px;
    height: 80px;
  }
`;
