import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaIdBadge, FaTrash } from 'react-icons/fa';
import { FiImage, FiMessageCircle } from 'react-icons/fi';
import { useParams } from 'react-router';
import ChatComponent from '../../components/ChatCopy';
import api from '../../services/api';
import Input from '../DashboardPages/core/components/Forms/Input';

import { Container, Grid } from './styles';


interface IProps {
  id: string;
}


const GreenRoom: React.FC = () => {
  const [currentClassName, setCurrentClassName] = useState('fadeIn');
  const [message, setMessage] = useState<JSX.Element | null>();
  const [type, setType] = useState('message');
  const { id } = useParams<IProps>();
  const [lowers, setLowers] = useState<Array<Record<string, any>>>([]);
  const [option, setOption] = useState('list');
  const [currentLower, setCurrentLower] = useState('')

  const load = async () => {

    const response = await api.get(`green-room/${id}`);


    setLowers(response?.data?.rows || [])


  }

  useEffect(() => {
    load();
  }, [])


  const sendToScreen = (type, content) => {

    const valid = {
      'lower': <div className={` lower`}>
        <div className='lowerImage'><img src="/assets/logo.png" /></div>
        <div className='lowerContent'>
          {content?.title ? <h2 style={{ textTransform: 'uppercase' }}>{content?.title}</h2> : <></>}
          {content?.company ? <strong>{content?.company}</strong> : <></>}
          {content?.position ? <strong>{content?.position}</strong> : <></>}</div>
      </div>,
      'message': <div className={` lower`}>
        <div className='lowerContent'>
          <h2>{content?.message}</h2>
          <strong>{content?.user_name}</strong>
        </div>
      </div>

    }

    if (valid[type]) {
      setMessage(valid[type]);


    }

  }

  const currentLowerValue = currentLower ? lowers.find(l => l._id.toString() === currentLower) || {} : {};

  const Lower: React.FC<IProps> = ({ id }) => {



    const submitLower = async (data) => {

      data.room_id = id;


      if (data._id) {
        await api.put(`/green-room/${data._id}/title`, { title: data.title });
        await api.put(`/green-room/${data._id}/company`, { company: data.company });
        await api.put(`/green-room/${data._id}/position`, { position: data.position });
        const newLowers = [...lowers];
        const index = lowers.findIndex(l => l._id === data._id);

        if (newLowers?.[index]) {

          load();
        }

        setOption('list');
      }
      else {
        const newData = await api.post('/green-room', { ...data });

        load();
        setOption('list');
      }




    }


    const changeToForm = (_id = '') => {

      setCurrentLower(_id);
      setOption('form');

    }


    const removeLower = async (idLower) => {
      await api.delete(`/green-room/${idLower}`);
      load();

      setCurrentLower('');
      setOption('list');
    }

    return (<div style={{ minWidth: '400px', border: '1px solid #fff', padding: '10px', borderRadius: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button className="SimpleButton" onClick={() => setOption('list')}> Listar </button>
        <button className="SimpleButton" onClick={() => { changeToForm() }}> + Adicionar</button>
      </div>
      {option === 'form' ? <Form onSubmit={submitLower} initialData={{ ...currentLowerValue }}>
        <Input name="_id" placeholder="ID" style={{ display: 'none' }} />
        <Input name="title" placeholder="Nome" />
        <Input name="company" placeholder="Empresa" />
        <Input name="position" placeholder="Cargo" />

        <button type="submit" className="SimpleButton">Salvar</button>

      </Form> : <></>}


      {option === 'remove' ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <h2 style={{ color: '#fff', margin: '10px auto' }}>Confirmar remoção?</h2>
        <button type="button" className="defaultButton" onClick={() => { removeLower(currentLower) }}>Confirmar</button>

        <button type="button" className="defaultButtonReverse" onClick={() => { setCurrentLower(''); setOption('list'); }}>Cancelar</button>

      </div> : <></>}

      {option === 'list' &&
        <div style={{ height: '45vh', overflowY: 'scroll' }}>
          {lowers.map((lower, index) => {
            return (<div style={{ display: 'flex', width: '100%', alignItems: 'center' }}><div style={{ width: '100%' }} className="lowerExample" onClick={() => { resetMessage('lower', lower) }} id={`lower-${lower?.hash}`}>
              <strong>{lower?.title}</strong>
              <p>{lower?.company}</p>
              <p>{lower?.position}</p>
            </div><button style={{ padding: '5px', width: '30px', height: '30px', borderRadius: '50%', border: '0px', background: '#fff', color: '#333' }} onClick={() => { setCurrentLower(lower._id); setOption('remove') }}><FaTrash /></button>

              <button style={{ padding: '5px', width: '30px', height: '30px', borderRadius: '50%', border: '0px', background: '#fff', color: '#333' }} onClick={() => { changeToForm(lower._id) }}><FaEdit /></button>
            </div>)
          })}
        </div>}

    </div>);

  }

  const resetMessage = (type, content) => {

    setCurrentClassName('fadeOut');



    setTimeout(() => {
      setMessage(null);
      setCurrentClassName('fadeIn');
      if (type) {
        sendToScreen(type, content);
      }
    }, 500);


  }


  return (
    <>
      <Container >
        <div className="holder">
          {message && <div className={currentClassName}>{message}</div>}
        </div>

        <div style={{ margin: '5px' }}>
          <Grid>
            <div onClick={() => setType('message')}> <FiMessageCircle /> </div>
            <div onClick={() => setType('lower')}> <FaIdBadge /> </div>
            <div onClick={() => setType('logo')}> <FiImage /> </div>
            <div>
              <button className="SimpleButton" onClick={() => resetMessage('', {})}>Limpar Tela</button>
            </div>
          </Grid>

          {type === 'message' && <div style={{ height: '45vh', overflowY: 'scroll' }}><ChatComponent setCallback={(content) => resetMessage('message', content)} id={id} /></div>}
          {type === 'lower' && <Lower id={id} />}
        </div>

      </Container>
    </>
  );
};
export default GreenRoom;
