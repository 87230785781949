import api from "../services/api";

const downloadit = async ({url, type = 'xlsx'}) => {

  const contentType = {
    xlsx : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv : 'application/csv'
  }

    try{
      const response = await api.get(url,{  
        headers:
        {
            'Content-Disposition': `attachment; filename=template.${type}`,
            'Content-Type': contentType[type] || ''
        },
        responseType: 'arraybuffer',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `relatorio.${type}`);
      document.body.appendChild(link);
      link.click();
  })
      .catch((error) => console.log(error));
    
    
    
    

      }catch(err) {
    
      
      }


      return true;
      }

     export const downloaditPost = async ({url, type = 'xlsx',data}) => {

        const contentType = {
          xlsx : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          csv : 'application/csv'
        }
      
          try{
            const response = await api.post(url,{ data},{  
              headers:
              {
                  'Content-Disposition': `attachment; filename=template.${type}`,
                 
              },
              responseType: 'arraybuffer',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `relatorio.${type}`);
            document.body.appendChild(link);
            link.click();
        })
            .catch((error) => console.log(error));
          
          
          
          
      
            }catch(err) {
          
            
            }
      
      
            return true;
            }   

      const downloaditPDF = async ({url}) => {

        try{
          const response = await api.get(url,{  
            headers:
            {
                'Content-Disposition': "attachment; filename=certificado.pdf",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'template.xlsx');
          document.body.appendChild(link);
          link.click();
      })
          .catch((error) => console.log(error));
        
        
        
        
    
          }catch(err) {
        
          
          }
          }

      export default downloadit;