import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';

import { defaultProjectHash } from '../../config';
import SignUp from '../SignUp';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SignUpContainer: React.FC = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container className='bg2'>
      <SignUp />
    </Container>
  );
};

export default SignUpContainer;
