import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, defaultProjectHash, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useAuth } from '../../../hooks/Auth';
import QRCodeUserModule from '../../DashboardPages/core/components/lineModules/QrCode';

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;


const vinculo = [
  { value: "Professor", label: "Professor(a)", text: "Professor(a)" },
  { value: "Contratado", label: "Contratado(a)", text: "Contratado(a)" },
  { value: "Residente", label: "Residente", text: "Residente" },
  { value: "Aluno de graduação", label: "Aluno(a) de graduação", text: "Aluno(a) de graduação" },
  { value: "Aluno de pós-graduação", label: "Aluno(a) de pós-graduação", text: "Aluno(a) de pós-graduação" },
  { value: "Pós-doutor", label: "Pós-doutor(a)", text: "Pós-doutor(a)" },
  { value: "Funcionário de empresa terceirizada", label: "Funcionário(a) de empresa terceirizada", text: "Funcionário(a) de empresa terceirizada" },
  { value: "Nenhum", label: "Nenhum", text: "Nenhum" },


]




const instituicao = [
  { value: "HCPA - Hospital de Clínicas de Porto Alegre", label: "HCPA - Hospital de Clínicas de Porto Alegre", text: "HCPA - Hospital de Clínicas de Porto Alegre" },
  { value: "ETS - Escola Técnica em Saúde", label: "ETS - Escola Técnica em Saúde", text: "ETS - Escola Técnica em Saúde" },
  { value: "Fadergs - Faculdade de Desenvolvimento do Rio Grande de Sul", label: "Fadergs - Faculdade de Desenvolvimento do Rio Grande de Sul", text: "Fadergs - Faculdade de Desenvolvimento do Rio Grande de Sul" },
  { value: "Feevale - Universidade Feevale", label: "Feevale - Universidade Feevale", text: "Feevale - Universidade Feevale" },
  { value: "GHC - Grupo Hospitalar Conceição", label: "GHC - Grupo Hospitalar Conceição", text: "GHC - Grupo Hospitalar Conceição" },
  { value: "HDP - Hospital Divina Providência", label: "HDP - Hospital Divina Providência", text: "HDP - Hospital Divina Providência" },
  { value: "HMD - Hospital Mãe de Deus", label: "HMD - Hospital Mãe de Deus", text: "HMD - Hospital Mãe de Deus" },
  { value: "HMV - Hospital Moinhos de Vento", label: "HMV - Hospital Moinhos de Vento", text: "HMV - Hospital Moinhos de Vento" },
  { value: "HMIPV - Hospital Materno Infantil Presidente Vargas", label: "HMIPV - Hospital Materno Infantil Presidente Vargas", text: "HMIPV - Hospital Materno Infantil Presidente Vargas" },
  { value: "HPS - Hospital de Pronto Socorro", label: "HPS - Hospital de Pronto Socorro", text: "HPS - Hospital de Pronto Socorro" },
  { value: "IC  (FUC) - Instituto de Cardiologia", label: "IC  (FUC) - Instituto de Cardiologia", text: "IC  (FUC) - Instituto de Cardiologia" },
  { value: "IFRS - Instituto Federal do Rio Grande do Sul", label: "IFRS - Instituto Federal do Rio Grande do Sul", text: "IFRS - Instituto Federal do Rio Grande do Sul" },
  { value: "IPA - Centro Universitário Metodista", label: "IPA - Centro Universitário Metodista", text: "IPA - Centro Universitário Metodista" },
  { value: "ISCMPA - Santa Casa de Misericórdia de Porto Alegre", label: "ISCMPA - Santa Casa de Misericórdia de Porto Alegre", text: "ISCMPA - Santa Casa de Misericórdia de Porto Alegre" },
  { value: "PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul", label: "PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul", text: "PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul" },
  { value: "Senac Saúde", label: "Senac Saúde", text: "Senac Saúde" },
  { value: "UCS - Universidade de Caxias do Sul", label: "UCS - Universidade de Caxias do Sul", text: "UCS - Universidade de Caxias do Sul" },
  { value: "Uergs - Universidade Estadual do Rio Grande do Sul", label: "Uergs - Universidade Estadual do Rio Grande do Sul", text: "Uergs - Universidade Estadual do Rio Grande do Sul" },
  { value: "UFCSPA - Universidade Federal de Ciências da Saúde de Porto Alegre", label: "UFCSPA - Universidade Federal de Ciências da Saúde de Porto Alegre", text: "UFCSPA - Universidade Federal de Ciências da Saúde de Porto Alegre" },
  { value: "UFJF - Hospital Universitário de Juiz de Fora", label: "UFJF - Hospital Universitário de Juiz de Fora", text: "UFJF - Hospital Universitário de Juiz de Fora" },
  { value: "UFPel - Universidade Federal de Pelotas", label: "UFPel - Universidade Federal de Pelotas", text: "UFPel - Universidade Federal de Pelotas" },
  { value: "UFRGS - Universidade Federal do Rio Grande do Sul", label: "UFRGS - Universidade Federal do Rio Grande do Sul", text: "UFRGS - Universidade Federal do Rio Grande do Sul" },
  { value: "UFSM - Universidade Federal de Santa Maria", label: "UFSM - Universidade Federal de Santa Maria", text: "UFSM - Universidade Federal de Santa Maria" },
  { value: "Ulbra - Universidade Luterana do Brasil", label: "Ulbra - Universidade Luterana do Brasil", text: "Ulbra - Universidade Luterana do Brasil" },
  { value: "UNICNEC - Centro Universitário Cenecista de Osório", label: "UNICNEC - Centro Universitário Cenecista de Osório", text: "UNICNEC - Centro Universitário Cenecista de Osório" },
  { value: "Unifesp - Universidade Federal de São Paulo", label: "Unifesp - Universidade Federal de São Paulo", text: "Unifesp - Universidade Federal de São Paulo" },
  { value: "Unilasalle - Universidade La Salle", label: "Unilasalle - Universidade La Salle", text: "Unilasalle - Universidade La Salle" },
  { value: "Unipampa - Universidade Federal do Pampa", label: "Unipampa - Universidade Federal do Pampa", text: "Unipampa - Universidade Federal do Pampa" },
  { value: "Uniritter - Laureate International Universities", label: "Uniritter - Laureate International Universities", text: "Uniritter - Laureate International Universities" },

  { value: "Unisinos - Universidade do Vale do Rio dos Sinos", label: "Unisinos - Universidade do Vale do Rio dos Sinos", text: "Unisinos - Universidade do Vale do Rio dos Sinos" },
  { value: "OUTRA", label: "Outros", text: "Outros" },



];


const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { projectHash = defaultProjectHash, eventHash, productId } = useParams<IParams>();
  const [companyValue, setCompanyValue] = useState('');
  const [emailRecord, setEmailRecord] = useState('');
  const { signIn, user } = useAuth();
  const [category, setCategory] = useState('');

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('new-subscriber');

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    data.extra_1 = data?.extra_1?.[0] ? data?.extra_1?.[0] : '';

    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }
    if (data?.company === 'OUTRA') {
      data.company = data?.company_value || '';
    }


    try {
      formRef.current?.setErrors({});


      const shape = {

        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),


        company: Yup.string().required('Instituição é obrigatório'),
        document_number: Yup.string().required('Campo CPF é obrigatório'),

        position: Yup.string().required('Campo Vínculo é obrigatório'),
        country: Yup.string().required('Campo País é obrigatório'),


        know_about: Yup.string().required('Campo Como souber do evento é obrigatório'),

      }



      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      //  data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '') || '';
      // data.birth_date = data?.birth_date?.toString()?.replace(/[^0-9/]/g, '') || '';
      data.document_number = data?.document_number?.toString()?.replace(/[^0-9]/g, '') || '';












      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: data?.term || '',
        fields: { ...fieldsValues },
        fields_text: fieldsText
      };

      if (productId) {
        content.product = productId;
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      const userInfo = signIn({ email: data?.email });

      setSuccess(true);
      setLoading(false);
      window?.scrollTo(0, 0);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '', product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';

    try {
      const checkUser = await api.post(`/check-subscriber`, { email: data.email, project_id: projectHash, event_id: eventHash || '', product: productId ? productId : '' });

      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email)
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }





  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Inscrições encerradas</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>Agradecemos seu interesse, mas as vagas estão encerradas</p>

        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          Voltar
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }



  if (statusConfirmation === 'update') {
    return <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: '#2e4336' }}>
                <h2 className="titleH2" style={{ color: '#2e4336', fontSize: '28px' }}> Cadastro realizado com sucesso!</h2>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no 43ª Semana Científica do HCPA. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: https://43-semana-cientifica-hcpa.encontrodigital.com.br`}
                  number=""
                  message="Compartilhe!"
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} style={{ padding: '15px', minWidth: '320px' }} onSubmit={handleNewLoginUpdate} initialData={{}}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Completar inscrição</h1>

            {product && product?.fields && product?.fields.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Finalizar inscrição


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  }



  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <div style={{ color: '#2e4336' }}>
                <h2 className="titleH2" style={{ color: '#2e4336', fontSize: '24px' }}> Parabéns pela inscrição! </h2>
                <br />

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="buttonBigGreen" type="button">
                      Acessar evento
                    </button>
                  </Link>
                </div>
                <br />
                Aproveite e indique para seus amigos!
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no 43ª Semana Científica do HCPA. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: 43-semana-cientifica-hcpa.encontrodigital.com.br`}
                  number=""
                  message="Compartilhe!"
                />
              </div>
            }
          />
        ) : (<>

          <Form name={projectHash} ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }} style={{ marginTop: '0px' }}>
            <h3 style={{ color: '#135b7a', textAlign: 'center', marginBottom: '20px', marginTop: '0px', fontSize: '32px' }}>Inscreva-se!</h3>



            <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />


            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />



            {countryISO === 'BR' ? <MaskedInput
              mask="999.999.999-99"
              name="document_number"
              placeholder={translate('CPF')}
            /> : <Input name="document_number" type='text' placeholder='Passaporte ou Documento local' />}









            <SelectSimple name="company" setCallback={(value) => setCompanyValue(value)} label="Instituição" options={instituicao} />

            {companyValue === "OUTRA" ?
              <Input name="company_value"
                placeholder={translate('Qual instituição')} /> : <></>}


            <SelectSimple name="position" label="Vínculo com o HCPA" options={vinculo} />







            <SelectSimple

              options={[
                { label: 'WhatsApp', value: 'WhatsApp' },
                { label: 'Indicação', value: 'Indicação' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Linkedin', value: 'Linkedin' },
                { label: 'E-mail marketing', value: 'E-mail marketing' },
                { label: 'SMS', value: 'SMS' },
                { label: 'Workplace', value: 'Workplace' },
                { label: 'Google', value: 'Google' },
                { label: 'Outros', value: 'Outros' },



              ]}
              name="know_about"
              formRef={formRef}
              label="Como ficou sabendo do evento?"
            />



            {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>Dados específicos do evento</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}


            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>

              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    {translate('As informações necessárias para emissão de certificados (nome e e-mail) serão arquivadas pelo HCPA e os demais dados deletados após o evento.')}

                  </>
                )}
                options={[
                  {
                    id: `Aceito receber divulgações dos eventos do HCPA.`,
                    label: 'Aceito receber divulgações dos eventos do HCPA.',
                    value: `Aceito receber divulgações dos eventos do HCPA.`,
                  },

                ]}
              />
              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >

                Estou ciente de que estou fornecendo ao 43ª Semana Científica do HCPA e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing do 43ª Semana Científica do HCPA, conforme Política de Privacidade disponível neste site.
                <br /><br />


                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="buttonBigGreen" type="submit">
                Finalizar inscrição


              </button>


            </aside>
            {/*   <p style={{ color: '#2e4336', padding: '15px', borderRadius: '15px', background: '#fff', textAlign: 'left', width: '100%', maxWidth: '600px', margin: '30px auto' }}>
              Estacionamento no local:<br />
              Teatro do Bourbon Country<br /><br />
              Carros: R$ 12,00 por até 3 horas, com adicional de R$ 2,00 para cada hora excedente.<br /><br />
              Motos R$ 7,00 por até 3 horas, com adicional de R$ 1,00 para cada hora excedente.<br /><br />
              Valores poderão sofrer alteração sem aviso prévio, conforme tabela do operador do local.
              </p> */}
          </Form>


        </>
        )}
      </aside>

    </>
  );
};

export default FormContainer;
