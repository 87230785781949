import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import Helmet from 'react-helmet';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { defaultProjectHash, hash, urlWebsite } from '../../../config';
import { useSocket } from '../../../hooks/Socket';
import { useVideo } from '../../../hooks/Video';
import FormContainerUpdate from '../../SignUpUpdate/FormContainer';
import { useModal } from '../../../hooks/Modal';
import { addDays } from 'date-fns/esm';
import { useToast } from '../../../hooks/Toast';
import HeaderLayout from '../../Header';
import { loadFromJson } from '../../../utils/loadFromJson';

interface IParams {
  projectHash: string;
  eventHash: string;
}


const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors, signOut } = useAuth();
  const { configuration, setConfiguration } = useConfiguration();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const { addModal } = useModal();

  const { addToast } = useToast();


  const { projectHash = defaultProjectHash, eventHash } = useParams<IParams>();



  const load = async () => {
    // setLoading(true);

    try {


      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);

        data = response?.data;
      }



      setConfiguration(data);



      if (data?.active_pages?.length > 0) {

        prepareMenu({ eventHash, projectHash, active_pages: data?.active_pages });
      }



      setLoading(false);
      setReady(true);

    }
    catch (err) {

      handleApiErrors(err);
      setLoading(false);
    }
  }


  useEffect(() => {

    if (user && !user.last_login) {
      signOut();
      window.location.reload();
    }

    if (user && user?.last_login) {

      const checkDate = addDays(new Date(), -5);
      const userDate = new Date(user.last_login);
      if (checkDate > userDate) {
        addToast({ title: 'Seu acesso expirou, faça seu login novamente', type: 'info' });

        setTimeout(() => {
          signOut();
          window.location.reload();
        }, 2000);
        return;
      }


    }

    if (user && user?.profile === 2 && user?.updated_information !== 'yes' && user?.updated_information !== 'no') {
      signOut();
      window.location.reload();
      return;
    }



    if (user && user?.updated_information !== 'yes' && user?.profile === 2) {

    }

  }, [user]);

  useEffect(() => {
    if (projectHash) {
      load();



      window.scrollTo(0, 0);
    }
    else {
      setLoading(false);
      setReady(true);
      setConfiguration({});
      setMenu([
        {
          title: 'Home',
          target: '/',
          targetID: '/',
          fluid: 1,
          external: 1,
          status: 2,
        }
      ]);
    }
  }, [projectHash, eventHash])

  const indexOf = window?.location?.pathname.indexOf('dashboard');

  return (
    <>
      {loading && <Loader message='Carregando' />}
      <Wrapper background_color={indexOf >= 0 ? '' : configuration.background_color} background={window?.location?.pathname !== '/' ? '/apoio/Fundo2.jpeg' : ''}>

        <Menu menuList={menu} fixedMenu={false} />



        {ready && <>
          {children}

          <PopupComponent />


        </>}
      </Wrapper>
      <FooterLayout />
      {configuration?.fixed_image_header && <img src={`${urlWebsite}/${configuration.fixed_image_header}`} className="fixedBottom" />}

      <Helmet>
        {configuration?.title && projectHash && <title>{`${configuration.title} 43ª Semana Científica do HCPA`}</title>}
        {!projectHash && <title>{`43ª Semana Científica do HCPA`}</title>}
      </Helmet>

    </>
  );
};

export default DefaultLayout;
