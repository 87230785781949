import React from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ISlider {
  autoplay?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  arrows?: boolean;
}

const SliderContainer: React.FC<ISlider> = ({
  autoplay = true,
  speed = 2000,
  slidesToShow = 3,
  slidesToScroll = 3,
  arrows = false,
  children,
}) => {
  const settings = {
    infinite: true,
    autoplay,
    speed,
    slidesToShow,
    slidesToScroll,
    dots: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 9000,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

    ],
  };
  return <Slider  {...settings}>{children}</Slider>;
};

export default SliderContainer;
