import React from 'react';

import { Container } from './styles';
import { logo } from '../../config';

interface ILoaderProps {
  message: string;
  position?: 'fixed' | 'relative' | 'absolute';
}

const Loader: React.FC<ILoaderProps> = ({
  message = 'Carregando...',
  position = 'fixed',
}) => {
  return (
    <Container position={position}>
      <img alt="Plataforma Online" src={logo} />
      <aside className='loaderH3'>{message}</aside>
    </Container>
  );
};

interface ILoaderWithElementProps {
  message: JSX.Element;
  position?: 'fixed' | 'relative' | 'absolute';
}

export const LoaderWithElement: React.FC<ILoaderWithElementProps> = ({
  message = <>Carregando...</>,
  position = 'fixed',
}) => {
  return (
    <Container position={position}>
      <img alt="Plataforma Online" src={logo} />
      <h3 className='loaderH3' style={{ margin: '0px auto', width: '100%', color: '#fff', textAlign: 'center' }}>{message}</h3>
    </Container>
  );
};

export default Loader;
