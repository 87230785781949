import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { background } from '../../config';
import theme from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: block;

  color: #fff;
  padding: 40px;
  width: 100%;
  max-width: 780px;

 // border: 2px solid rgb(0,15,64);
  margin:  30px auto;
  background: rgba(255,255,255,0.9) !important;
  padding: 30px 30px;
  border-radius: 15px;

  display: flex ;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
   

  tr{
    background: none !important ;
  }
  @media (max-width: 750px) {
    padding: 40px   !important;
    border-radius: 0px;
  }

   

 

  > h2, >h1 {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }

  tr{
    transition: 0.5s all;
    background: #fff;


    .hourDiv{
      max-width: 140px;
      min-width: 140px;
      @media (max-width: 750px) {
        max-width:85px;
        min-width: 85px;
  }

    }

    .optionDiv{
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        flex-direction: column;
  }
    }

    &:hover{
      
 
      
    }

    >td {
      padding: 15px 10px !important;
      border-bottom: 1px solid #2b9861 !important;
    }
  }

  td { 
    padding: 10px 5px;
    color: #333;
  }

  .miniPrograma {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;

    color: #333 !important;

    p,strong,span{
      color: #333 !important;
      font-size: 16px !important;
    }

    strong{
      color : rgb(241, 102, 33);
     
    }
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
            }
            > td + td {
              
              width: 100%;
            }
          }
        }
      }
    }
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }


  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;
        padding: 5px 10px;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
