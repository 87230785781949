import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal } from '../../styles';
import { useSocket } from '../../../../hooks/Socket';

import { useToast } from '../../../../hooks/Toast';
import Button from '../../../Button';
import Input from '../../../Forms/Input';
import { useParams } from 'react-router-dom';
import { useVideo } from '../../../../hooks/Video';
import { FiX } from 'react-icons/fi';
import { useLanguage } from '../../../../hooks/Language';
import { FaDownload } from 'react-icons/fa';
import { urlWebsite } from '../../../../config';
import { Container } from './styles';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';
interface ICreator {
  room_id?: string;
  module_id?: number;
  active?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

interface IReceiver {
  room_id?: string;
  module_id?: number;

}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export const track = ({ id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

  /* ID do trabalho
  titulo do trabalho
  reference é a ação
  track o que é
  
  */

  api.post('/add-track', { id, title, reference, track, client_id: client_id, client_name, project_id: 'lesson' });


}

export const UploadContainer: React.FC<IReceiver> = () => {



  const { courses, openVideoId, currentRoom } = useVideo();
  const { user } = useAuth();
  if (currentRoom && currentRoom?.uploads && currentRoom?.uploads?.length > 0) {
    return <Container>
      <h2>Arquivos de apoio</h2>
      {currentRoom?.uploads?.map(upload => {

        return <div key={upload?.url}>
          <a onClick={() => { track({ id: currentRoom?._id, title: currentRoom?.title, reference: upload?.title, track: 'download', client_id: user?.id, client_name: user?.name }) }} download href={`${urlWebsite}/${upload?.url}`} target="_BLANK"> <FaDownload /> <p>{upload?.title}</p></a>
        </div>

      })}
    </Container>
  }
  else {
    return <></>
  }


};

