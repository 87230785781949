import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container } from './styles';


interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Local: React.FC = () => {


  return (
    <aside style={{
      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, minHeight: '50vh', background: 'none',
      backgroundSize: "cover",
      backgroundAttachment: "fixed"
    }}>


      <Container >
        <aside>
          <h1 style={{ color: '#02938e', marginBottom: '30px', fontSize: '32px', textAlign: 'center', width: '100%' }}>Local </h1>
          <p style={{ color: '#2e4336', textAlign: 'left', width: '100%', margin: '0px auto' }}>
            Teatro do Bourbon Country<br />Av. Túlio de Rose, 80, Porto Alegre, RS<br /><br />
          </p>
          <img src="/apoio/local.jpg" style={{ width: '100%', borderRadius: '15px' }} />
          <p style={{ color: '#2e4336', textAlign: 'left', width: '100%', margin: '0px auto' }}>
            <br />
            Estacionamento no local:<br />
            <br />
            Carros: R$ 12,00 por até 3 horas, com adicional de R$ 2,00 para cada hora excedente.<br /><br />
            Motos R$ 7,00 por até 3 horas, com adicional de R$ 1,00 para cada hora excedente.<br /><br />
            Valores poderão sofrer alteração sem aviso prévio, conforme tabela do operador do local.
          </p>
        </aside>

      </Container>
    </aside>
  );
};
export default Local;
