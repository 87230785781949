import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { defaultTheme } from '../../config';
import themeDefault from '../../styles/ThemeComponents';

interface IContainer {
  sticky: boolean;
  fixedMenu: boolean;
  activeMenu: boolean;
  className: string;
}

interface INav {
  sticky: boolean;
  fixedMenu: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  padding-top: 0;
  position: ${props => (props.fixedMenu ? 'fixed' : 'relative')};
  top: 0px;
  left: 0px;
  z-index: 7000;

  .menuHead{
    width: 100% !important;
  max-width: auto !important;
  min-width: auto !important;

  justify-content: center !important;

  @media (max-width: 750px) {
    justify-content: space-between !important;
   
    }

  ul{
    padding-left: 0px;
    width: auto;
    max-width: auto !important;
  min-width: auto !important;

  @media (max-width: 750px) {
    width: 100%;
   
    }
  }
  }

.logo-outubro{
  width: 200px;
      max-width: 200px;
}

.logoHolder{
 
  >a {
    .logo1 {
      width: 80px;
    }

    .logomain {
      width: 300px;
      max-width: 70%;
    }
    .logomain2 {
      width: 120px;
      max-width: 120px;
    }
    .logo2 {
      width: 200px;
      max-width: 200px;
    }

    

    @media (max-width: 750px) {
      padding: 0px;
      .logomain {
      width: 65px;
      max-width: 65px;
    }
    .logomain2 {
      width: 65px;
      max-width: 65px;
    }
      .logo1 {
        width: 40px;
    }
    .logo2 {
      width: 75px;
      max-width: 75px;
    }
    }
  }
}

  ${props => props.activeMenu && css`
  min-height: 100vh;
  `}

  background: ${props =>
    props.fixedMenu
      ? defaultTheme.menu.background
      : defaultTheme.menu.backgroundSticky};
  width: 100%;
  transition: background 0.5s;

  ${props =>
    props.sticky &&
    css`
      background: ${defaultTheme.menu.backgroundSticky};
    `}

  .row {
    display: flex;
    flex-direction: row;

    @media (max-width: 740px) {
      margin-top: 20px;
    }
  }

  .onlyComputer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 750px) {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: ${defaultTheme.menu.color};
    font-weight: bold;
    transition: color 0.5s;

    &:hover {
      opacity: 0.9;
    }

    @media (max-width: 750px) {
      color: #2e4336;
    }
  }
`;

interface ILink {
  background: string;
  extend?: boolean;
}

export const Icon = styled.a<ILink>`
  width: ${props => (props.extend ? 'auto' : '50px')};
  height: 50px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  transition: 0.2s all;
  text-decoration: none;
  background: none;

  border-radius: 8px;
  opacity: 0.7;
  color: ${props => (props.background ? props.background : '#444')};

  svg {
    font-size: 40px;
    color: ${themeDefault.globalBackground};
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    text-decoration: none;
  }

  &:hover {
    font-size: 24px;
    opacity: 1;
  }
`;

export const Navigation = styled.div<INav>`
  display: flex;
  width: 100%;

  padding: 15px;
  z-index: 9;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: 'relative';
  top: 0;
  left: 0;
  background: none;
  transition: 0.2s all;

  height: auto;
  max-height: 122px;
 z-index: 100000;
   transform: translateY(0px) ;
  padding:30px ;
  @media (max-width: 750px) {
    justify-content: space-between;
    max-height: 100vh;
    padding:10px 30px ;
    }
  .logoDiv {
    @media (max-width: 750px) {
      padding: 30px;
    }
  }

  img {
    width: ${defaultTheme.logo.minWidth};
    margin-left: 10px;
    margin-right: 10px;
    max-width: 120px;
    transition: 0.5s all;

    ${props =>
    props.sticky &&
    css`
        max-width: 80px;
      `}

    @media (max-width: 750px) {
      max-width: 150px;
      margin: 0px 5px;
    }
  }
`;

interface INavigation {
  activeMenu: boolean;
}

export const NavigationList = styled.ul<INavigation>`
  display: flex;
  flex-direction: row;
  transition: 0.5s all;
  margin-top: -500%;
  align-items: center;

  width: 50%;
  max-width: 50%;
  min-width: 50%;
  padding-left: 60px;

  @media (max-width: 1200px) {
    padding-left: 0px;
    width: 100%;
  max-width: 100%;
  min-width: 100%;
    }

  .mobileOnly {
    display: none !important;
    @media (max-width: 1200px) {
      display: flex !important;
    }
  }

  a:not(.socialIcon) {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      min-height: auto;
    }

    @media (max-width: 750px) {
      margin: 0;
      flex-direction: column;
      width: 100%;
    }
  }

  li.closeMenu {
    border-radius: 4px;
    cursor: pointer;
    color: ${defaultTheme.menu.color};
    transition: 0.2s all;
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    justify-content: flex-end;
    padding-right: 1rem;
    border: 0px;
  }

  @media (min-width: 741px) {
    display: flex;
    margin-top: 0;

    li.closeMenu {
      display: none;
    }
  }

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: -500%;
    margin-top: ${props => (props.activeMenu ? '0px' : '-500%')};
    z-index: 11;
    background: ${defaultTheme.defaultBackground};
    padding: 20px 10px;

    li.closeMenu {
      display: flex;
    }
  }

  li {
    cursor: pointer;
    width: auto;
    list-style: none;
    display: block;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    transition-delay: 1s all;
    color: ${defaultTheme.menu.color};
    border-bottom: 0.1rem solid rgba(26, 26, 25, 0.1);
    font-family: Simplon Norm, Helvetica Neue, Helvetica, Roboto, Arial,
      sans-serif;
    font-size: 1rem;
    transition: 0.5s all;

    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: ${props => (props.activeMenu ? '1rem' : '1.5rem')};
    margin-bottom: ${props => (props.activeMenu ? '0' : '50%')};

    >a{
      background: #3c8d66 ;
color: #fff;
padding: 10px;
border-radius: 10px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  li + li {
    border-left: 0px solid #fff;
  }

  @media (min-width: 741px) {
    li {
      padding: 5px;
      font-size: 0.9rem;
      border: 0;
      border-left: 0rem solid rgba(26, 26, 25, 0.1);
      margin-bottom: 0;
      color: ${defaultTheme.menu.color};
    }
  }
`;

export const MobileMenuBackground = styled.div<INavigation>`
  transition: 0.2s all;
  @media (min-width: 741px) {
    display: none;
  }

  @media (max-width: 740px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: ${props => (props.activeMenu ? '0' : '-100%')};
    height: 100%;
    width: 100%;
    background: #000;
    opacity: ${props => (props.activeMenu ? '0.3' : '0')};
    z-index: 10;
  }
`;

export const LinkLi = styled(Link)`
  text-decoration: none;
  color: ${defaultTheme.menu.color};
  font-weight: bold;
  transition: color 0.5s;

  &:hover {
    opacity: 0.9;
  }
`;
export const MenuIcon = styled.div`
  @media (max-width: 750px) {
    display: flex;
    color: ${defaultTheme.menu.color};
  }

  @media (min-width: 750px) {
    display: none;
  }
`;

interface IIconSet {
  column: boolean;
}

export const IconSet = styled.div<IIconSet>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin: 0px 10px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
  }
`;

export const IconSet2 = styled.div<IIconSet>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
  }
a {
  opacity: 1;
  margin: 0px;
  width: 70px;
  svg {
    min-width: 60px !important;
    font-size: 60px !important;
  }
}

svg {
  color : rgb(0,14,64) !important;
}
`;

export const TextMessage = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  max-width: 200px;
  display: flex;

  @media (max-width: 740px) {
    margin-bottom: 10px;
    text-align: center;
    align-items: center;
  }
`;


export const LanguageSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;
  min-width: 150px;

  > div {
    display: flex;
    max-width: 35px;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    width: auto;
    cursor: pointer;
    > img {
      width: 32px;
    }
  }
`;

interface IBackground {
  background: string;
}
export const Photo = styled.div<IBackground>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #fff;

  flex-direction: row;
  flex-wrap: wrap;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  margin: 0px 5px;
border-radius: 50%;
background : url(${props => props.background}) no-repeat center;
background-size: cover;


transition: 0.5s all;

>p{
  opacity: 0;
  color: #fff;
  font-weight:bold;
  font-size: 8px;


  text-align: center;
}
&:hover {
  background : rgba(100,100,100,0.7);
  transform : scale(1.2);
 >p{
   opacity: 1;
  
 }
}
`;