import React, { useState } from 'react';
import { FaChalkboardTeacher, FaChartBar, FaFileExcel, FaSpinner } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';
import api from '../../../../../services/api';
import { useModal } from '../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}


const ExportSurveyListReport: React.FC<ICreateModule> = ({
  lineData,
}) => {
  const { addModal } = useModal();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const getDownload = async (data) => {

    const response = await api.get(`${apiUrl}/get-research-list-report/${data._id}`);


    const getStatus = (value) => {

      const newValue = parseInt(value, 10);

      if (newValue >= 80) {
        return <img src='/emoji_survey/love.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 60) {
        return <img src='/emoji_survey/good.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 40) {
        return <img src='/emoji_survey/normal.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 20) {
        return <img src='/emoji_survey/bad.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 0) {
        return <img src='/emoji_survey/sad.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
    }


    addModal({
      title: 'Relatório CSAT', theme: 'whiteModal', content: <div>
        <p style={{ maxWidth: '600px', width: '100%', color: '#333', padding: '10px', fontSize: '14px', marginBottom: '15px' }}>
          Resultado de {response?.data?.[0]?.total || '0'} pesquisas
        </p>
        <table style={{ color: '#333', maxWidth: '600px', width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>Pegunta</td>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}><img src='/emoji_survey/sad.png' style={{ width: '30px', margin: '0px 10px' }} /></td>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}><img src='/emoji_survey/bad.png' style={{ width: '30px', margin: '0px 10px' }} /></td>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}><img src='/emoji_survey/normal.png' style={{ width: '30px', margin: '0px 10px' }} /></td>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}><img src='/emoji_survey/good.png' style={{ width: '30px', margin: '0px 10px' }} /></td>
              <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}><img src='/emoji_survey/love.png' style={{ width: '30px', margin: '0px 10px' }} /></td>
            </tr>
            {response?.data?.map((item, indexItem) => {
              console.log(item);
              return <tr key={`answer-${indexItem}`}>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.title}</td>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.v1?.value || '0%'}</td>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.v2?.value || '0%'}</td>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.v3?.value || '0%'}</td>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.v4?.value || '0%'}</td>
                <td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{item?.v5?.value || '0%'}</td>
              </tr>


            })}
          </tbody>
        </table>


      </div>
    })

  }

  const moduleList = ({ data }): JSX.Element => {

    return <> {user && user.profile === 1 ? loading ? <FaSpinner style={{ margin: '5px', cursor: 'pointer' }} className='rotateAnimation' size={20} /> : <FaChartBar style={{ margin: '5px', cursor: 'pointer' }} color="#333" onClick={() => getDownload(data)} size={30} /> : <></>}
    </>;
  }



  return moduleList({ data: lineData });
}

export default ExportSurveyListReport;