export const hash = '0733c8d4-56b3-4263-ab2f-78c82d3b18ba';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6LcRcUYlAAAAADcNgqZgGU3jeSEzdV0NfMHidvBM';
export const apiUrl = `https://api-global-v2.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';
//export const apiSocket = 'http://localhost:8001';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://43-semana-cientifica-hcpa.encontrodigital.com.br';
export const supportNumber = `5551981476007`;

export const defaultProjectHash = '43-semana-cientifica-hcpa';

export const home = 'Home';
export const haveTranslate = false;
// export const urlWebsite = 'http://localhost:3000';
export const eventName = '43ª Semana Científica do HCPA';
export const logo = '/assets/logo.png';
export const background = '/assets/Fundo.jpeg';
export const authTitle = '43ª Semana Científica do HCPA';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: '#d8d0bd',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: '#d8d0bd',
    color: '#2e4336',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
