import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import downloadCertificate from './modules/ExportPDF';
import downloadAllCertificates from './modules/ExportAll';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const CertificatesUsers: React.FC = () => {
  const endpoint = '/certificates-user';
  const title = 'Certificados Gerados';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    certificate_id: {
      ref: 'certificate_id',
      column: 'certificate_id_response',
      includeColumn: 'title',
      label: 'Produto',
    },
    user_id: {
      ref: 'user_id',
      column: 'user_id_response',
      includeColumn: 'name',
      label: 'Cliente', show: false,
    },
    content: { ref: 'content', column: 'content', label: 'Content', show: false },
    image: { ref: 'image', column: 'image', label: 'Imagem', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'E-mail' },
    document_number: { ref: 'document_number', column: 'document_number', label: 'CPF' },
    authors: { ref: 'authors', column: 'authors', label: 'AUTORES' },
    activity: { ref: 'activity', column: 'activity', label: 'ATIVIDADE' },
    paper: { ref: 'paper', column: 'paper', label: 'TRABALHO' },
    category: { ref: 'category', column: 'category', label: 'CATEGORIA' },
    modal: { ref: 'modal', column: 'modal', label: 'MODALIDADE' },
    days: { ref: 'days', column: 'days', label: 'DIAS' },
    hours: { ref: 'hours', column: 'hours', label: 'HORAS' },
    height: { ref: 'height', column: 'height', label: 'ALTURA DO CERTIFICADO' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    hash: {
      model: 'input',
      type: 'text',
      name: 'hash',
      label: 'Hash',
    },
    user_id: {
      alias: 'user_id',
      model: 'selectApi',
      name: 'user_id',
      label: 'Cliente',
      endpoint,
    },
    certificate_id: {
      alias: 'certificate_id',
      model: 'selectApi',
      name: 'certificate_id',
      label: 'Certificado',

      endpoint,
    },
    image: {
      model: 'input',
      type: 'text',
      name: 'image',
      label: 'IMAGEM', show: false
    },
    name: {
      model: 'input',
      type: 'text',
      name: 'name',
      label: 'NOME',
    },
    email: {
      model: 'input',
      type: 'text',
      name: 'email',
      label: 'E-MAIL',
    },
    document_number: {
      model: 'input',
      type: 'text',
      name: 'document_number',
      label: 'CPF',
    },
    authors: {
      model: 'input',
      type: 'text',
      name: 'authors',
      label: 'AUTORES',
    },
    coauthors: {
      model: 'input',
      type: 'text',
      name: 'coauthors',
      label: 'COAUTORES',
    },
    paper: {
      model: 'input',
      type: 'text',
      name: 'paper',
      label: 'TRABALHO',
    },
    activity: {
      model: 'input',
      type: 'text',
      name: 'activity',
      label: 'ATIVIDADE',
    },
    category: {
      model: 'input',
      type: 'text',
      name: 'category',
      label: 'CATEGORIA',
    },
    modal: {
      model: 'input',
      type: 'text',
      name: 'modal',
      label: 'MODALIDADE',
    },
    days: {
      model: 'input',
      type: 'text',
      name: 'days',
      label: 'DIAS',
    },
    hours: {
      model: 'input',
      type: 'text',
      name: 'hours',
      label: 'HORAS',
    },
    height: {
      model: 'input',
      type: 'text',
      name: 'height',
      label: 'ALTURA DO CERTIFICADO', show: false
    },
  };






  const formSchemaUpdate = {
    ...formSchema,

  };


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () => downloadAllCertificates()
    ],
    bodyModules: [

      (lineData: Record<string, any>) => downloadCertificate({ lineData }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CertificatesUsers;
