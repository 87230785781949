import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}


const EventsAdmin: React.FC = () => {
  const endpoint = '/events';
  const title = 'Eventos';

  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    contador: { ref: 'contador', column: 'contador', label: 'Contador' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    start_at: { ref: 'start_at', column: 'start_at', label: 'Data de início' },

    date_text: { ref: 'date_text', column: 'date_text', label: 'Data em texto' },
    image: { ref: 'image', column: 'image', label: 'Foto', show: false },
    status: { ref: 'status', column: 'status', label: 'Ativo?' },
    selected: { ref: 'selected', column: 'selected', label: 'Evento Atual?' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    contador: { model: 'input', type: 'text', name: 'contador', label: 'Contador' },
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    start_at: { model: 'datePickerHour', type: 'text', name: 'start_at', label: 'Data' },
    date_text: { model: 'input', type: 'text', name: 'date_text', label: 'Data em texto' },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    selected: {
      model: 'selectSimple',
      name: 'selected',
      label: 'Evento Atual?',
      defaultValue: 'Não',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    presence_type: {
      model: 'selectSimple',
      name: 'presence_type',
      label: 'Tipo de evento',
      defaultValue: 'local',
      options: [
        { label: 'Online', value: 'online' },
        { label: 'Híbrido', value: 'hibrid' },
        { label: 'Presencial', value: 'local' },
      ]
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo de evento',
      defaultValue: 'free',
      options: [
        { label: 'Gratuito', value: 'free' },
        { label: 'Pago', value: 'paid' },
      ]
    },
    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto padrão',
      endpoint,
    },

    sales_limit_type: {
      model: 'selectSimple',
      name: 'sales_limit_type',
      label: 'Restringir quantidade?',
      defaultValue: 'no',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    sales_limit: { model: 'input', type: 'text', name: 'sales_limit', label: 'Quantas Vagas?' },
    active_pages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'active_pages',
      label: 'Páginas / Menu',
      list: {
        hash: { label: 'Hash', name: 'hash' },
        title: { label: 'Título', name: 'title' },
        url: { label: 'Link', name: 'url' },
        menu: { label: 'Menu?', name: 'menu', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        signed: { label: 'Logado?', name: 'signed', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        type: { label: 'Tipo', name: 'type', type: 'select', options: [{ text: 'Página principal', value: 'page' }, { text: 'Aba separada', value: 'single_page' }, { text: 'Modal', value: 'modal' }, { text: 'Link externo', value: 'external' }, { text: 'Menu', value: 'menu' }] },
      },
      defaultValue:
        [
          { hash: 'home', url: '', title: 'Home', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'product', url: 'inscricao', title: 'Venda', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signup', url: 'inscricao', title: 'Inscrição', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'program', url: 'programacao', title: 'Programação', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'speakers', url: 'palestrantes', title: 'Palestrantes', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'poster', url: 'poster', title: 'Pôster', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'certificate', url: 'certificados', title: 'Certificados', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'files', url: 'arquivos-de-apoio', title: 'Arquivos de Apoio', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'faq', url: 'faq', title: 'FAQ', menu: 'no', active: 'no', signed: 'no', type: 'page' },

        ]
    },


  };

  const formSchemaUpdate = {


    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    thumb: {
      model: 'uploadImage',
      type: 'upload',
      name: 'thumb',
      label: 'Thumb',
    },
    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsAdmin;
