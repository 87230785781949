import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import Speakers from '../../../../../pages/Speakers';
import Sponsors from '../../../../../pages/Sponsors';
import Sobre from '../../../../../pages/Sobre';
import {

  Capa,

} from './styles';
import Program from '../../../../../pages/Program';
import ProgramTheme from '../../Program';
import EventsProjectList from '../../../../../pages/DashboardPages/features/EventsProjectList';

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SinglePageTheme: React.FC = () => {
  const { configuration } = useConfiguration();


  const { projectHash, eventHash } = useParams<IParams>();



  return (
    <>   <div style={{ display: 'flex', width: '100%', background: 'rgba(0,0,0,0.3)', alignItems: 'center', justifyContent: 'center' }}>

      <>
        {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{ margin: '10px' }}><button className="defaultButton">Certificados</button></Link> : <></>}
        <a href={!eventHash ? `/app/${projectHash}/inscricao` : `/app/${projectHash}/event/${eventHash}/inscricao`} style={{ margin: '5px 10px' }}><button style={{ padding: '10px', minWidth: '100px', margin: '5px', width: '120px' }} className="defaultButtonReverse">Inscreva-se</button></a>

        <a href={!eventHash ? `/app/${projectHash}/login` : `/app/${projectHash}/event/${eventHash}/login`} style={{ margin: '5px 10px' }}><button className="defaultButton" style={{ padding: '10px', margin: '5px', minWidth: '100px', width: '120px' }}>Entrar</button></a>

      </>


    </div>
      <Capa style={{ alignItems: eventHash ? 'center' : 'flex-start', justifyContent: 'center' }}  >
        <div style={{ display: 'flex', width: '100%', maxWidth: '700px', flexDirection: 'column', alignItems: 'center' }} >

          {(configuration?.current_event_id_response?.image || configuration.header_image) && <div>
            <img style={{ maxWidth: '100%', height: 'auto', opacity: 1, borderRadius: '15px', maxHeight: '80vh', margin: '0px auto' }}
              src={`${urlWebsite}/${configuration?.current_event_id_response?.image || configuration.header_image}`} />

          </div>}

          <div style={{ display: 'flex', width: '100%', maxWidth: '480px', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>


            {configuration.data_image ? <img src={`${urlWebsite}/${configuration.data_image}`} style={{ width: '100%', opacity: 1, maxWidth: '500px', margin: '0px auto' }} /> : <></>}



          </div>


        </div>
        <div style={{ display: 'flex', width: '100%', maxWidth: '480px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{ margin: '10px' }}><button className="defaultButton">Certificados</button></Link> : <></>}


          <EventsProjectList />





        </div>

      </Capa>
      {configuration?.current_event_id_response?.description_title ? <Capa>
        <Sobre />
      </Capa> : <></>}

      <div style={{ background: '#fff', width: '100%' }}> <ProgramTheme />

      </div>


      <Speakers />

      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Sponsors />
      </div>

    </>
  );
};
export default SinglePageTheme;
