import React, { useState, useRef, useEffect } from 'react';

import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';


import { useAuth } from '../../../../../../hooks/Auth';



import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';


interface ICreateModule {
  content: string;

}

const QRCodeUserModule: React.FC<ICreateModule> = (
  { content }
) => {
  const { user } = useAuth();

  const { addPrint, printIt } = usePrinter();
  const key = uuid();

  const print = () => {
    printIt();
  };

  const handleQR = async (): Promise<void> => {
    addPrint([
      <div className="qrHolder"
        style={{
          padding: '20px 5px',
          background: '#fff',
          width: '100%',
          textAlign: 'center',

        }}
      > <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', width: '100%'
      }}> <img src="/assets/logo.png" style={{ width: '200px' }} />   <QRCode style={{ marginTop: '5px', marginLeft: '30px' }}

        size={120}
        includeMargin={true}
        imageSettings={{ src: "", width: 120, height: 120, excavate: false }}
        renderAs="svg"
        value={user.user_hash}
          /></div>
        <div style={{ marginTop: '20px', padding: '20px', textAlign: 'left', color: '#000', fontSize: '16px', }}>
          {user.name && <h1 style={{ color: '#000', fontSize: '16px', padding: '15px' }}>Participante: {user.name}</h1>}
          <strong style={{ color: '#000', fontSize: '16px', padding: '15px' }}> Evento presencial:</strong><br />
          <p style={{ color: '#000', fontSize: '16px', padding: '15px' }}>
            Imprima este documento com o QRCode ou salve no seu celular, pois será o seu ingresso no Evento.
          </p>

          <p style={{ color: '#000', fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: content }} />

        </div>




      </div>]
    );

  };

  useEffect(() => {
    if (user) {
      handleQR();
    }
  }, [user])



  return <>

    <div className="qrHolder" style={{ padding: '20px', background: '#fff' }}><QRCode
      renderAs="svg"

      size={300}
      includeMargin={true}
      value={
        user?.user_hash
      }
    /></div> <Button style={{ margin: '10px auto' }} onClick={() => print()}>Imprimir</Button> </>


}


export default QRCodeUserModule;