import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';
 
import DefaultTheme from './modules/Themes/Default';
import DasaTheme from './modules/Themes/SidebarTheme';
import SinglePageTheme from './modules/Themes/SinglePageTheme';
import SidebarEventTheme from './modules/Themes/SidebarThemeEvents';

interface IParams {
  projectHash : string;
}

const HomeApplication: React.FC = () => {
 
 
  const {translate} = useLanguage();

  const {configuration} = useConfiguration();

  const [theme,setTheme] = useState('');
  
  const {projectHash, eventHash} = useParams<Record<string,any>>();

  useEffect(() => {

    let themeOption = configuration?.theme_data?.["page-theme"] || 'default';

    if(themeOption === 'single_page' && eventHash){
      themeOption = 'sidebar_event_theme';
    }

    setTheme( themeOption || 'default')

  },[configuration])

  const themes = {
      default : <DasaTheme/>,
      dasa : <DasaTheme/>,
      single_page : <SinglePageTheme/>,
      sidebar_event_theme : <SidebarEventTheme/>

  }

  

  return ( themes?.[theme] ? themes?.[theme] : <></>  );
};
export default HomeApplication;
